import { combineReducers } from 'redux';
import intialize from './intialize/reducer';
import shuffleData from './shuffledata/reducer';
import trackingUser from './trackinguser/reducer';
import screenChange from './screentransistion/reducer';

const rootReducer = combineReducers({
  intialize,
  shuffleData,
  trackingUser,
  screenChange,
});

export default rootReducer;
