// import { useState, useEffect } from 'react';

// const Counter = () => {
//   const [seconds] = useState(240);
//   const [sec, setSec] = useState(59);
//   const [minutes, setMinutes] = useState(0);

//   useEffect(() => {
//     setMinutes(seconds / 60 - 1);
//     // eslint-disable-next-line
//   }, []);
//   useEffect(() => {
//     const time = setInterval(() => {
//       if (minutes === 0 && sec === 0) {
//         return;
//       }
//       if (sec === 0) {
//         setMinutes((minutes) => minutes - 1);
//         return setSec(59);
//       }
//       setSec(sec - 1);
//     }, 1000);
//     return () => clearInterval(time);
//     // eslint-disable-next-line
//   }, [sec]);
//   return [minutes, sec];
// };

// export default Counter;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Counter = () => {
  const [seconds] = useState(240);
  const [sec, setSec] = useState(59);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setMinutes(seconds / 60 - 1);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const time = setInterval(() => {
      if (minutes === 0 && sec === 0) {
        return;
      }
      if (sec === 0) {
        setMinutes((minutes) => minutes - 1);
        return setSec(59);
      }
      if (minutes === 0 && sec === 31) {
        toast.info(
          <div>
            <p style={{ fontSize: "20px" }}>30 seconds left</p>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      setSec(sec - 1);
    }, 1000);
    return () => clearInterval(time);
    // eslint-disable-next-line
  }, [sec]);

  return (
    <>
      <ToastContainer
        position="top"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {`0${minutes}`}:{sec < 10 ? `0${sec}` : sec}
    </>
  );
};

export default Counter;
