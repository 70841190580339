import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import UnMuteMusic from '../../../assets/images/audiocontrols/tile000.png';
import UnMuteAudio from '../../../assets/images/audiocontrols/tile003.png';
import MuteMusic from '../../../assets/images/audiocontrols/tile001.png';
import MuteAudio from '../../../assets/images/audiocontrols/tile004.png';
import startVoiceOver from '../../../utils/playVoiceOver';
import { usePageVisibility } from 'react-page-visibility';
import music from '../../../assets/audio/BGMusic.mp3';
import { isWindowOpen } from '../../../store/screentransistion/actions';
import {
  toggleMusic,
  toggleVoiceOver,
} from '../../../store/screentransistion/actions';

const AudioControls = ({
  toggleMusic,
  toggleVoiceOver,
  playMusic,
  playVoiceOver,
  isWindowOpen,
  isWindowActive,
}) => {
  const musicRef = useRef(null);
  const isVisible = usePageVisibility();

  useEffect(() => {
    musicRef.current = startVoiceOver(music);
    if (!playMusic || !isVisible) {
      musicRef.current.pause();
    }
    return () => {
      musicRef.current.pause();
    };
    // eslint-disable-next-line
  }, [playMusic, isVisible]);

  useEffect(() => {
    isWindowOpen(!isWindowActive);
    // eslint-disable-next-line
  }, [isVisible]);

  const handleClick = (event) => {
    if (event.target.name === 'music') {
      toggleMusic(!playMusic);
    } else {
      toggleVoiceOver(!playVoiceOver);
    }
  };
  return (
    <>
      <div className="audio__controls__container">
        <div>
          <img
            src={playMusic ? UnMuteMusic : MuteMusic}
            alt="audio"
            name="music"
            className="music"
            onClick={handleClick}
          />
        </div>
        <img
          src={playVoiceOver ? UnMuteAudio : MuteAudio}
          id="voiceOver"
          name="voiceOver"
          alt="audio"
          className="audio"
          onClick={handleClick}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    progress: state.progress,
    languages: state.intialize.languages,
    zones: state.intialize.zones,
    narrationData: state.shuffleData.narrationDataSet,
    audioData: state.shuffleData.narrationDataSet.narrationDataList,
    numeracyDetails: state.trackingUser.levelDetails,
    currentScreen: state.screenChange.screenID,
    levels: state.shuffleData.levels,
    playMusic: state.screenChange.playMusic,
    playVoiceOver: state.screenChange.playVoiceOver,
    isWindowActive: state.screenChange.isWindowOpen,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
    toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
    isWindowOpen: (isOpen) => dispatch(isWindowOpen(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(AudioControls);
