import React, { useEffect, useRef } from 'react';
import './style.scss';
import playMusic from '../../../utils/playMusic';

const AsherIntro = ({
  asher,
  text,
  alt,
  audioClip,
  narrationAssetUrl,
  playVoiceOver,
  isWindowActive,
}) => {
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = playMusic(`${narrationAssetUrl}${audioClip}`);

    if (!playVoiceOver || !isWindowActive) {
      audioRef.current.pause();
    }
    return () => audioRef.current.pause();
    // eslint-disable-next-line
  }, [playVoiceOver, isWindowActive]);
  return (
    <>
      <div className="asher__intro__container__main">
        <div className="text__label">
          <p>{text}</p>
        </div>
        <img src={asher} alt={alt} />
      </div>
    </>
  );
};

export default AsherIntro;
